import React, { useContext } from "react";
import { Radio, Button, Select, Row, Col, DatePicker } from "antd";
import moment from "moment";
import styles from "../BillingAccountStatements.module.css";
import UserContext from "../../../contexts/userContext";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const CampaignFilters = ({
  filters,
  setFilters,
  submit,
  dateRange,
  setDateRange,
}) => {
  const { userInfo } = useContext(UserContext);

  return (
    <>
      <div className={styles.filtersContainer}>
        <Radio.Group
          className={styles.dateRadioSelect}
          defaultValue="30days"
          size="large"
        >
          <Radio.Button
            value="today"
            onClick={() => setDateRange({ from: moment(), to: moment() })}
          >
            Today
          </Radio.Button>
          <Radio.Button
            value="yesterday"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "days"),
                to: moment().subtract(1, "days"),
              })
            }
          >
            Yesterday
          </Radio.Button>
          <Radio.Button
            value="7days"
            onClick={() =>
              setDateRange({ from: moment().subtract(6, "days"), to: moment() })
            }
          >
            Last 7 Days
          </Radio.Button>
          <Radio.Button
            value="30days"
            onClick={() =>
              setDateRange({
                from: moment().subtract(30, "days"),
                to: moment(),
              })
            }
          >
            Last 30 Days
          </Radio.Button>
          <Radio.Button
            value="thisMonth"
            onClick={() =>
              setDateRange({
                from: moment().startOf("month"),
                to: moment().endOf("month"),
              })
            }
          >
            This Month
          </Radio.Button>
          <Radio.Button
            value="lastMonth"
            onClick={() =>
              setDateRange({
                from: moment().subtract(1, "months").startOf("month"),
                to: moment().subtract(1, "months").endOf("month"),
              })
            }
          >
            Last Month
          </Radio.Button>
          <Radio.Button
            value="allTime"
            onClick={() =>
              setDateRange({
                from: moment(userInfo.date_joined),
                to: moment(),
              })
            }
          >
            All Time
          </Radio.Button>
        </Radio.Group>
        <div style={{ marginTop: "1%", paddingBottom: "0.5rem" }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <span className={styles.spanMarginBottom}>Date</span>
              <RangePicker
                style={{ width: "100%" }}
                allowClear={false}
                // className={styles.rangeDatePicker}
                onChange={(momentDates, dates) => {
                  setDateRange({ from: momentDates[0], to: momentDates[1] });
                }}
                value={[moment(dateRange.from), moment(dateRange.to)]}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <span className={styles.spanMarginBottom}>Platform</span>
              <Select
                size="medium"
                className={styles.selectFormats}
                placeholder="Select platform"
                allowClear
                mode="multiple"
                onChange={(value) => {
                  setFilters({ ...filters, platform: value });
                }}
              >
                <Option value="offerwall">Affiliate Programs</Option>
                <Option value="publisher">Webmasters</Option>
                <Option value="extension">Search</Option>
                <Option value="n2s">N2S</Option>
              </Select>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <span className={styles.spanMarginBottom}>Statement type</span>
              <Select
                size="medium"
                className={styles.selectFormats}
                placeholder="Select statement type"
                allowClear
                mode="multiple"
                onChange={(value) => {
                  setFilters({ ...filters, type: value });
                }}
              >
                <Option value="revenue">Revenue</Option>
                <Option value="payment">Payment</Option>
                <Option value="stats_correction">Stats correction</Option>
                <Option value="other_statements">Other statements</Option>
              </Select>
            </Col>{" "}
            <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
              <Button
                className={styles.applyFiltersBtn}
                style={{ marginTop: 22 }}
                onClick={() => submit(filters)}
                type="primary"
                size="medium"
              >
                Apply Filters
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
