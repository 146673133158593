import moment from "moment";
import { callApi } from "./api";

const sevenDays = moment().subtract(7, "days").format("YYYY-MM-DD");
const today = moment().subtract(1, "days").format("YYYY-MM-DD");

const handleOrdering = (sorting, checkedColumns) => {
  const ordering = [];
  if (Object.keys(sorting).length === 0) {
    if (
      Object.keys(checkedColumns).includes("revenue") &&
      Object.keys(checkedColumns).includes("date") &&
      Object.keys(checkedColumns).includes("hour")
    ) {
      ordering.push("date");
      ordering.push("hour");
      ordering.push("revenue");
    } else if (
      Object.keys(checkedColumns).includes("country") ||
      Object.keys(checkedColumns).includes("search_channel")
    ) {
      ordering.push("revenue");
    } else {
      ordering.push("date");
    }
  } else {
    for (const key in sorting) {
      const val = sorting[key];
      if (val) {
        if (val === "ascend") {
          ordering.push("-" + key);
        } else {
          ordering.push(key);
        }
      }
    }
  }
  return ordering;
};
const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return "";
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== "";
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return query ? (withoutQuestionMark ? query : `?${query}`) : "";
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize,
});

class AgencyService {
  async getCountries() {
    return await callApi({
      url: "/api/cns/countries/",
    });
  }
  async getOperatingSystems() {
    return await callApi({
      url: "/api/cns/os/",
    });
  }
  async getBrowsers() {
    return await callApi({
      url: "/api/cns/browsers/",
    });
  }

  async getStatsPagedDaily({
    selectedFilters,
    currentPage,
    pageSize,
    sorting,
    cancelToken,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "campaign_id";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });

    const ordering = handleOrdering(sorting, checkedColumns);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      timeframe: "daily",
      country: filters.country && filters.country.join(","),
      status: filters.status && filters.status.join(","),
      search_channel:
        filters.search_channel && filters.search_channel.join(","),
      ordering: ordering && ordering.join(","),
    });
    return await callApi({
      url: `/api/agency/stats/${query}`,
      cancelToken,
    });
  }

  async exportReportsDaily({
    selectedFilters,
    currentPage,
    pageSize,
    sorting,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "campaign_id";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });

    const ordering = handleOrdering(sorting, checkedColumns);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      timeframe: "daily",
      status: filters.status && filters.status.join(","),
      search_channel:
        filters.search_channel && filters.search_channel.join(","),
      ordering: ordering && ordering.join(","),
    });
    window.location = `/api/agency/stats/?resType=csv&${query}`;
  }
  async getStatsPagedHourly({
    selectedFilters,
    currentPage,
    pageSize,
    sorting,
    cancelToken,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "campaign_id";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });
    if (checkedColumns["creator_first_name"]) {
      colParameter += ",creator_last_name";
    }

    const ordering = handleOrdering(sorting, checkedColumns);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      timeframe: "hourly",
      status: filters.status && filters.status.join(","),
      search_channel:
        filters.search_channel && filters.search_channel.join(","),
      ordering: ordering && ordering.join(","),
    });
    return await callApi({
      url: `/api/agency/stats/${query}`,
      cancelToken,
    });
  }

  async exportReportsHourly({
    selectedFilters,
    currentPage,
    pageSize,
    sorting,
  }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = "campaign_id";

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += "," + key;
      }
    });

    const ordering = handleOrdering(sorting, checkedColumns);
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: dateRange.from.format("YYYY-MM-DD"),
      date_to: dateRange.to.format("YYYY-MM-DD"),
      timeframe: "hourly",
      status: filters.status && filters.status.join(","),
      search_channel:
        filters.search_channel && filters.search_channel.join(","),
      ordering: ordering && ordering.join(","),
    });
    window.location = `/api/agency/stats/?resType=csv&${query}`;
  }

  async changeTargetingStatus(data) {
    return await callApi({
      url: "/api/campaigns/targeting/",
      method: "POST",
      data,
    });
  }

  async getChannels(timeframe) {
    return await callApi({
      url: `/api/agency/autocomplete/?table_type=${timeframe}`,
    });
  }

  async getTopCardInfoChart() {
    return await callApi({
      url: `/api/agency/stats/?resType=chart&timeframe=daily&columns=date,spent,revenue,gross_profit,profit,rpc,cpc&date_from=${sevenDays}&date_to=${today}&offset=0&limit=10&ordering=-date`,
      method: "GET",
    });
  }
  async getTopCardInfo() {
    return await callApi({
      url: `/api/agency/stats/?resType=table&timeframe=daily&columns=date,spent,revenue,gross_profit,profit,rpc,cpc&date_from=${sevenDays}&date_to=${today}&offset=0&limit=10&ordering=-date`,
      method: "GET",
    });
  }

  async getStatisticData(columns) {
    return await callApi({
      url: `/api/agency/stats/?resType=chart&timeframe=daily&columns=date,${columns.join(
        ","
      )}&date_from=${sevenDays}&date_to=${today}&offset=0&limit=10&ordering=data`,
      method: "GET",
    });
  }

  async getTopChannelsData() {
    return await callApi({
      url: `/api/agency/stats/?resType=table&timeframe=daily&columns=search_channel,impressions,clicks,search_cr,ctr,cpc,revenue,rpc&date_from=${sevenDays}&date_to=${today}&offset=0&limit=10&ordering=revenue`,
      method: "GET",
    });
  }

  async getEarningsMapData() {
    return await callApi({
      url: `/api/agency/stats/?resType=table&timeframe=daily&columns=country,revenue&date_from=${sevenDays}&date_to=${today}&offset=0&limit=6&ordering=revenue`,
      method: "GET",
    });
  }
}

export const agencyService = new AgencyService();
